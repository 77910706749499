<template>
  <base-template>
    <div class="content">
      <div class="main-title">
        <div class="title-item">CashAgad's PRIVACY POLICY</div>
      </div>
      <p>CashAgad ("CashAgad ", "We", "Us", or "Our", for brevity) hereby adopts this Privacy Policy in compliance with the provisions of Republic Act No. 10173, entitled: An Act Protecting Individual Personal Information and Communications Systems in The Government and The Private Sector, Creating for This Purpose a National Privacy Commission, And for Other Purposes, otherwise known as the "Data Privacy Act of 2012".</p>
      <p>For this purpose, this Privacy Policy ensures that the personal data gathered from our clients in the course of transactions, and in accordance with the nature of our business, shall remain secured and protected.</p>
      <p>This Privacy Policy describes what information we collect from you, the purposes for which we collect and process it, how we use it, who we share it with, how long we retain it, and your rights in relation to it. You must read this Privacy Policy in its entirety. </p>
      <p class="p40">(the "CashAgad App") and your use of our services through the CashAgad App or otherwise. It does not apply to your use of the Company's website</p>

      <h6 class="title-item">1. What data does CashAgad collect and process?</h6>
      
      <p class="p10">In order to facilitate your loan application, we collect,SMS,applications, monitor and upload your contact information to the  servers {{ureduduu}}. The information collected includes names, phone numbers, account types, last modified contact details, favorites and other optional data. And the information will enable you to automatically fill in requested data during the loan application process. This information also helps us better access your risk profile and perform risk analyses, enabling us to identify credible references and further evaluate your loan eligibility.</p>
      <p class="p10">This information will be only uploaded to the   server once and will be encrypted and sealed along with the risk model in your personal file. In case of needing to clear any uploaded information, you can contact our customer service.Data provided by you through your loan application (e.g., information about your income or employment, your proposed use of your loan proceeds, customer-provided information about your mobile device);</p>
      <p class="p10">
        We need to access app list installed by the user and upload it to the server for credit scoring;
      </p>
      <p class="p10">
        Call detail record, we will obtain your call detail record to verify the authenticity of your mobile number, and it will be used for credit evaluation.
      </p>
      <p class="p10">Mobile device data (e.g., SIM, IMEI, or other device identifiers, type of device, device operating system, device settings, user account information for your mobile device , the name and network information of your mobile network provider, device specifications (such as screen size, resolution, etc.), information about network proximity);</p>
      <p class="p10">Data related to your communications with CashAgad (e.g., your communications with CashAgad via in-app chat, email, telephone or other channels); </p>
      <p class="p10">The service-related information we collect may involve your mobile phone number, the date, time and content of your text messages, including any information you choose to provide through text messages. We will use this information to contact you and provide you with the services you have requested in accordance with our Privacy Policy. Do not submit any personal information via SMS. The usage data will be strictly encrypted, will not be used for any other purpose, and will not be sold or shared with any third party.</p>
      <p class="p10">CashAgad may collect your Information from:</p>
      <p class="p10">You, when download the CashAgad App and/or indicate that you want to apply for a loan;</p>
      <p class="p10">Your other interactions with us, including Information you may voluntarily share with our customer support team or other CashAgad employees or agents;</p>
      <p class="p10">Call detail record, we will obtain your call detail record to verify the authenticity of your mobile number, and it will be used for credit evaluation;</p>
      <p class="p10">Your mobile device (e.g., mobile device data, location data, transaction data, CashAgad App usage and/or phone data as more fully described above);</p>
      <p class="p10">Credit reference agencies (who may check your Information against other databases - public and private - to which they have access) or fraud prevention agencies; and/or Third parties and other publicly available sources, with your consent, when necessary (e.g., we may receive Information from financial rails (or other disbursement channel vendors) or other business partners that may assist us in providing services to you).</p>
      <p class="p10">Other information: We may collect some of your other personal information only after obtaining your additional explicit consent. For example, the phone numbers you add to your contacts application and your SMS logs in device may be collected for risk control purposes; and your location information (IP address, longitude and latitude information) may be collected for anti-fraud purposes.</p>
      <p class="p40">Collection and processing of your Information by CashAgad is necessary for the provision of CashAgad 's products and services and/or to comply with applicable legal and regulatory requirements to which you and/or CashAgad is subject. Aside from such cases, we do not collect Information without your specific prior consent.  </p>

      <h6 class="title-item">2. What are the purposes for which your Information is processed? How does CashAgad use your Information?</h6>
      <div class="item-content">
        <p class="p10">a. Any User information collected by CashAgad is for the purpose of ensuring the smooth, effective and safe transactions by the Users. CashAgad may obtain the following information and upload it to our server({{ureduduu}})</p>
        <p class="p10">b. We collect information for the following purposes, including but not limited to (“Purposes”):</p>
        <p class="p10">To assess your eligibility to use our products or services (including, but not limited to, by assessing your creditworthiness, determining whether you can afford a product or service you requested and/or determining whether you are eligible for additional benefits of an existing product);</p>
        <p class="p10">To service your loan with CashAgad (including, but not limited to, by processing your transactions and processing disbursement of your loan and collecting repayment of your loan);</p>
        <p class="p10">To verify your identity and/or other information you’ve provided to us;</p>
        <p class="p10">To detect, combat and prevent fraud, attempted fraud, money laundering and/or other illegal uses of our services;</p>
        <p class="p10">To analyze customer behavior;</p>
        <p class="p10">To administer out systems, maintain service quality and compile general usage statistics;</p>
        <p class="p10">To analyze and improve our services;</p>
        <p class="p10">To troubleshoot any problems, you or other customers encounter with our services;</p>
        <p class="p10">To comply with applicable laws, regulations, and rules, such as those relating to “know-your-customer” and/or anti-money laundering;</p>
        <p class="p10">To the extent you have not objected to the use of your information for direct marketing purposes after proper notification, to send you marketing or advertising notices or other promotional offers;</p>
        <p class="p10">To provide service updates;</p>
        <p class="p10">To provide staff training (where we may monitor or record customer interactions);</p>
        <p class="p10">To interface with credit reference or fraud prevention agencies;</p>
        <p class="p10">To provide customer service or support;</p>
        <p class="p10">We process your Information for the purposes set out above on the following grounds: </p>
        <p class="p10">In connection with our legitimate interest in (1) providing you with credit and/or other financial or technological products or services, (2) operating our business, (3) marketing our products and services to you and others and (4) administering our systems and keeping our records up to date;</p>
        <p class="p10">To carry out our obligations to you as a result of any contracts or agreements entered into between you and us (i.e., where necessary for the adequate performance of our contract with you and/or to take steps requested by you prior to entering into a contract with you); and/or with your prior consent.</p>
        <p class="p40">Collection and processing of your Information by CashAgad is necessary for the provision of CashAgad ’s products and services and to comply with applicable legal and regulatory requirements to which you and/or CashAgad is subject. Apart from such cases, we do not collect Information without your specific prior consent. </p>
      </div>

      <h6 class="title-item">3. How does CashAgad use your Information?</h6>
      <p class="p10">a. Automated decisions and profiling</p>
      <p class="p10">We may make certain decisions in relation to our provision of our products and services to you by using automated decision-making processes, without human involvement.</p>
      <p class="p10">Lending decisions</p>
      <p class="p10">When you apply for a loan, we’ll use automated processing to decide whether to lend to you and/or make other decisions about your eligibility for our products and services, based on the Information collected. This automated processing enables us to provide rapid, responsive and tailored credit services to customers who may not have credit histories, prior bank or other financial data or income from formal sources. </p>
      <p class="p10">Our credit and underwriting models utilize data science and machine-learning technology to process your Information and assess your creditworthiness. The associated processing of your Information is automated and little to no human intervention is involved. Using such automated processes to assess your creditworthiness means we may automatically decide that you may be ineligible for a loan or ineligible for a loan of a particular amount or tenure. Our credit and underwriting models are regularly tested to ensure they remain fair, accurate and unbiased.</p>
      <p class="p10">Detecting fraud</p>
      <p class="p10">
        CashAgad also utilizes automated processes to detect, combat and prevent fraud. Our fraud models may automatically decide that a certain individual poses a fraud or money laundering risk (e.g., if our processing reveals information or behavior consistent with money laundering or known fraudulent activity, if the activity is inconsistent with prior activity on our platform or if an individual appears to be hiding their true identity). If our fraud models determine that processing of a transaction or approving a certain individual creates a risk of fraud, that individual’s access may be suspended or refused. Your rights in relation to automated decision-making If you apply for a loan and your loan is declined (or you are informed that you are ineligible for other or associated products or services) as a result of an automated credit, underwriting or fraud decision, you may request that one of our underwriters review this decision. You may contact us through the contact information shown on the mobile app</p>
      <p class="p10">b. Develop and manage our relationships with you and our Business Partners. This may include: (i) delivering services or carrying out transactions that you or our Business Partners have requested; (ii) providing information about CashAgad  products, services, and transactions, and advertisements, that may be of interest to you; (iii) providing you and our Business Partners a more consistent experience in interacting with CashAgad , including by learning more about you and how you use and interact with CashAgad  Internet Sites, Apps, products, and services; and (iv) planning, managing, and performing under our contractual relationships with our Business Partners.</p>
      <p class="p10">c. Communicate with you. This may include: (i) informing you of CashAgad  products, services, and promotional activities that may be of interest to you; (ii) providing information about relevant CashAgad  products, services, and transactions, including, for example, pricing, information about product or service improvements; (iii) responding to questions or inquiries that you make, including customer service requests; and (iv) inviting you to participate in, or informing you of the results of, customer satisfaction or market research surveys.</p>
      <p class="p10">d. Provide and improve our Internet Sites, Mobile Application, products, and services. This may include: (i) customizing them to your preferences or interests, making them more compatible with your technology, or otherwise making them easier to use; (ii) maintaining the security of and otherwise protecting them; and (iii) and developing new CashAgad Internet Sites, Mobile Application, products and services.</p>
      <p class="p40">e. Address legal issues. This may include: (i) complying with our obligations to retain certain business records for minimum retention periods; (ii) establishing, exercising, or defending legal claims; (iii) complying with laws, regulations, court orders, or other legal process; (iv) detecting, preventing, and responding to fraud, intellectual property infringement, violation of our contracts or agreements, violations of law, or other misuse of CashAgad  Internet Sites, Apps, products or services; and (v) protecting CashAgad ’s rights or property, or yours or others’ health, safety, welfare, rights, or property.  </p>

      <h6 class="title-item">4. Who do we share your Information with?</h6>
      <p class="p10">General</p>
      <p class="p10">CashAgad shall not disclose any Information containing personal data (as defined under applicable law) to any third parties, unless it is necessary and/or appropriate in order to provide CashAgad 's products or services, provided that, we may share limited personal data (as defined under applicable law) with select partners for the purpose of research and development. Whenever practically feasible, CashAgad will only share your Information with third parties in an anonymized format.</p>
      <p class="p10">You agree that we may, as necessary and/or appropriate for the purposes provided in section 2, transfer and disclose your information to:</p>
      <p class="p10">a subsidiary, affiliate or parent company of CashAgad (collectively, the "CashAgad Group");</p>
      <p class="p10">employees, subcontractors, agents, service providers, or associates of the CashAgad Group (including directors and officers);</p>
      <p class="p10">intermediary, correspondent and agent banks, non-banks, quasi-banks or other financial institutions, clearing houses, clearing or settlement systems, market counterparties, upstream withholding agents, licensed electronic or mobile wallet providers, credit reference agencies or credit bureaus;</p>
      <p class="p10">service providers with contractual or fiduciary relationships with CashAgad (e.g., to facilitate transaction processing, fraud prevention, cloud data storage or data transfer);external collection agencies (to assist in the collection of any unpaid obligations to us);tax authorities;</p>
      <p class="p10">a party in connection with any merger, acquisition or sale of all or substantially all of the assets of CashAgad and/or any company within the CashAgad Group;</p>
      <p class="p10">to other third parties to the extent (1) we have a duty to disclose or share your information in order to comply with any legal obligation, (2) necessary or appropriate to enforce or apply any agreement with you, and/or (3) necessary or appropriate to protect the rights or safety of CashAgad , the CashAgad  Group and/or our customers.</p>
      <p class="p10">The above parties may also process or disclose your information for the purposes set forth in Section 2, so long as such processing or disclosure is in compliance with this Privacy Policy. </p>
      <p class="p10">
        Further, CashAgad may also share your information with law enforcement or other government agencies pursuant to a formal request, subpoena, court order, or similar legal procedure, or when we believe in good faith that disclosure is necessary to comply with the law, prevent physical harm or financial loss, to report suspected illegal activity, or to investigate violations of our agreements with you.</p>
      <p class="p10">Referrals</p>
      <p class="p10">To promote its products and services, CashAgad administers and services a referral program ("CashAgad Referral Program", for brevity) for its current and future customers. If you choose to accept a referral from another CashAgad customer and accept a CashAgad loan in connection with that referral, CashAgad will provide a bonus to the customer who refers you upon your successful repayment of your CashAgad loan. </p>
      <p class="p10">If you choose to participate in the CashAgad Referral Program as a referee, you specifically consent to the sharing of such anonymized information about your repayment status by accepting the referral, clicking on the imbedded referral link and subsequently applying for and accepting a CashAgad loan through such referral. </p>
      <p class="p40">Sharing of your Information in connection with the CashAgad Referral Program shall be limited to the information described above and shall remain anonymized  - CashAgad will never share identifiable or personalized data about your repayment status (or any other personal data (as defined under applicable law) with any other customer or user or CashAgad . More information on the CashAgad Referral Program is available within the CashAgad App.</p>

      <h6 class="title-item">5. For how long will we retain your Information?</h6>
      <p class="p10">Your Personal Data will be stored or retained by CashAgad and its transferees only for so long as necessary for the purposes listed in Sections 2 and 10 of this Privacy Policy, and for a reasonable time thereafter for the delivery of our products and completion of our services. We may also retain your Information (i) as long as necessary to comply with any legal obligation; (ii) whenever such is authorized under the law; and (iii) for the establishment of legal defenses.</p>
      <p class="p10">Your Personal Data will be retained by the Company for the duration of your activities and transactions in connection with the products and services availed of in this CashAgad  Facility and/or for such period of time required for legal and regulatory and/or other legitimate business purposes, and will be disposed of in a secure manner that would prevent further processing, unauthorized access or disclosure to any other party or the public or prejudice your interest, provided that the Company may retain copies of your Personal Data in the Company’s archives for the purpose of determining its continuing obligations or pursuant to its bona fide record retention or data back-up policies, access to which shall be restricted on a need-to-know basis, as may be required under applicable laws and regulations. </p>
      <p class="p40">The Personal Data may likewise be retained for the purpose of fraud prevention, detection, and monitoring in accordance with the Company’s fraud detection and prevention policies, and any requirement imposed by any credit agency, bureau or agency in order to prevent fraud, identify theft, anti-money laundering, terrorist financing and other similar causes which are consistent with public policy and the public good.</p>

      <h6 class="title-item">6. Where do we process, store or transfer your information?</h6>
      <p class="p10">Your Personal Data including your transaction data is stored in the secure third-party cloud service provided by our cloud service providers (Amazon and Alicloud) who are among the best-of-breed cloud providers in the industry.</p>
      <p class="p10">We may transfer your Information outside of the Philippines, either to the United States, or Singapore, and/or to the extent we provide prior notice to you, to jurisdictions that may have data protection laws that are less stringent than those of the Philippines. </p>
      <p class="p40">When we do so, we will ensure that adequate safeguards are in place in accordance with applicable law and/or the Philippines ‘data protection requirements. The safeguards we will use will depend on the circumstances and the party to whom we transfer your Information. Your Information may be processed by any of the parties described in Section 3. CashAgad will use all reasonable and appropriate efforts as required by applicable law to protect your Information.</p>

      <h6 class="title-item">7. Automated decisions and profiling</h6>
      <p class="p10">We may make certain decisions in relation to our provision of our products and services to you by using automated decision-making processes, without human involvement.</p>
      <p class="p10">Lending decisions</p>
      <p class="p10">When you apply for a loan, we shall use automated processing to decide whether to commence with the transaction or otherwise based on your eligibility for our products and services. This automated processing enables us to provide rapid, responsive and tailored credit services to customers who may not have credit histories, prior bank or other financial data or income from formal sources.</p>
      <p class="p10">Our credit and underwriting models utilize machine-learning technology to process your Information and assess your credit-worthiness. The associated processing of your Information is automated and little to no human intervention is involved. Using such automated processes to assess your creditworthiness means we may automatically decide that you may be ineligible for a loan or ineligible for a loan of a particular amount or tenure. Our credit and underwriting models are regularly tested to ensure they remain fair, accurate and unbiased.</p>
      <p class="p10">Detecting fraud</p>
      <p class="p10">CashAgad also utilizes automated processes to detect, combat and prevent fraud. Our fraud models may automatically decide that a certain individual poses a fraud or money laundering risk (e.g., if our processing reveals information or behavior consistent with money laundering or known fraudulent activity, if the activity is inconsistent with prior activity on our platform or if an individual appears to be hiding their true identity). If our fraud models determine that processing of a transaction or approving a certain individual creates a risk of fraud, that individual's access may be suspended or refused.</p>
      <p class="p10">Your rights in relation to automated decision-making</p>
      <p class="p40">In the event that your loan application is denied or your loan is declined, or you are found ineligible for other or associated products or services as a result of an automated credit, underwriting or fraud decision, you may request that said decision be reviewed through {{email}}.  </p>

      <h6 class="title-item">8. Your rights as a data subject</h6>
      <p class="p10">You may contact us to exercise your rights at {{email}}.</p>
      <p class="p10">Rights to notice and consent</p>
      <p class="p10">We shall provide you with the necessary information as to how we collect your Information, how we use the same (and our legal basis for doing so), who we share your Information with, where we obtained your Information and your rights as a data subject. This information is provided within the CashAgad App and in this Privacy Policy.</p>
      <p class="p10">Right to access</p>
      <p class="p10">You may ask for a copy of the personal data (as defined under applicable law) in our possession (and your Information related to such personal data), unless providing some or all of it would adversely affect the rights and freedoms of others or applicable law requires that we do not comply with your request.</p>
      <p class="p10">Right to rectification</p>
      <p class="p10">You may ask us to correct any Information which you believe to be inaccurate. We will promptly update such information. In connection with your request, you may be required to provide supporting evidence or other documentation so that we may verify the accuracy of the thereof.</p>
      <p class="p10">Right to erasure ("right to be forgotten")</p>
      <p class="p10">You may ask us to delete your personal data (as defined under applicable law) from our system:</p>
      <p class="p10">If you believe it is no longer necessary for us to retain such personal data;</p>
      <p class="p10">If you do not believe we have legitimate ground for processing it;</p>
      <p class="p10">If you think we are using such personal data unlawfully; and/or</p>
      <p class="p10">If you think applicable law requires that we do so.</p>
      <p class="p10">Right to restrict processing</p>
      <p class="p10">You may ask us to stop using your personal data (as defined under applicable law):</p>
      <p class="p10">if you think such personal data is inaccurate; </p>
      <p class="p10">if you think it's illegal for us to use such personal data;</p>
      <p class="p10">if you don't want us to destroy such personal data because you need it for legal proceedings;</p>
      <p class="p10">if you've informed us that we don't have a legitimate reason for using it and we're considering your request.</p>
      <p class="p10">Right to data portability</p>
      <p class="p10">If we're using your information on the basis of your consent or because we need it to carry out our contractual obligations to you, you can ask us to give you your personal data (as defined under applicable law) in a structured, commonly-used and machine-readable format or have it transmitted to another data controller. </p>
      <p class="p10">Right to object</p>
      <p class="p10">You have a right to object whenever we are using your personal data (as defined under applicable law) on the basis of our legitimate interests (Please see section 2 for the ways in which we rely on legitimate interests to use and process such personal data).</p>
      <p class="p10">Right to file a complaint</p>
      <p class="p10">You have the right to file a complaint with the appropriate government agencies for any violation of your rights as a data subject, including the National Privacy Commission of the Philippines.</p>
      <p class="p40">Please note that there may be occasions when you wish to exercise your rights and we are unable to agree to your request (e.g., because we have compelling legitimate grounds for using or processing your information or because we need to retain your information to comply with a legal obligation).  </p>

      <h6 class="title-item">9. Advertising and Marketing</h6>
      <p class="p40">You may opt out from receiving advertising, marketing or promotional messaging by contacting us and making such request through {{email}} and we will remove you from such communication lists. </p>

      <h6 class="title-item">10. Consequences of not providing us with your Information</h6>
      <p class="p40">You are not required to provide us with your Information or any associated personal data (as defined under applicable) and you may withdraw your consent from the use or processing of such Information or personal data. However, we likewise reserve the right to you are your application or terminate our relationship with you, due to failure to conduct a sufficiency and exhaustive evaluation on our part and/or as permitted under applicable law/s. Further, to the extent we have a legitimate interest in retaining your Information and/or associated personal data (as defined under applicable law), we may do so. For example, if you have requested that we erase your Information or associated personal data (as defined under applicable law), but you have not repaid your loan with CashAgad , we may retain your Information or associated personal data (as defined under applicable law), in order to continue collection efforts.</p>

      <h6 class="title-item">11. Consent and Authorization</h6>
      <p class="p10">By downloading the CashAgad App, you: </p>
      <p class="p10">accept the terms of this Privacy Policy;</p>
      <p class="p10">give CashAgad consent to collect, use, share, or otherwise process your Information, which may include personal information, sensitive personal information or privileged information (in each case, as defined under applicable law), as outlined in this Privacy Policy;</p>
      <p class="p10">certify that all Information you have provided and will provide to CashAgad is true and correct to the best of your knowledge;</p>
      <p class="p10">authorize CashAgad to verify/investigate the accuracy of your Information; and</p>
      <p class="p40">acknowledge that CashAgad may be required to disclose your Information to the Bangko Sentral ng Pilipinas, Anti-Money Laundering Council, credit bureaus and/or any other governmental body, in compliance with its legal obligations.</p>

      <h6 class="title-item">12. General</h6>
      <p class="p10">If you need to contact us, please do so at: {{email}}</p>
      <!-- <p class="p10">To improve and continue our services to you, this Privacy Policy may be updated from time to time. We will notify you prior to any updates to this Privacy Policy, either within the CashAgad App or by email.</p> -->
    </div>
  </base-template>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      ureduduu:'',
      email:'CashAgad@outlook.com'
    }
  },
  created(){
    
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.changeStatusIsDarkFont(true)
    
  },
  mounted () {
    // this.email = ""
    this.ureduduu=this.$store.state.settings.andirodData.ureduduu || ''
    this.ureduduu = this.ureduduu.slice(0,this.ureduduu.length-1)
  }
}
</script>
<style lang="scss" scoped>
.content{
  padding-top: 64px;
  font-size: 28px;
  color: #666666;
  line-height: 44px;
}
.main-title{
  font-size: 32px;
  font-weight: 500;
  color: #000000;
}
.title-item{
  font-family: 'Calibri';
  font-weight: bold;
  // margin-bottom: 15px;
}
.p10{
  padding-bottom: 10px;
}
.p20{
  padding-bottom: 20px;
}
.p40{
  padding-bottom: 40px;
}
// .item-content{
//   padding-left: 40px;
// }
</style>
